const categories = [
  {
    id: 0,
    category: "WOMEN",
    count:3
  },
  {
    id: 1,
    category: "MEN",
    count:1
  },
  {
    id: 2,
    category: "KIDS",
    count:1
  },

  {
    id: 3,
    category: "HOODIES",
    count:0
  },

  {
    id: 4,
    category: "JACKETS",
    count:0
  },

  {
    id: 5,
    category: "UNISEX",
    count:0
  },
];

export default categories;
