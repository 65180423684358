import React from 'react'
import loading from './assests/logo/loading.gif'

export default function Loading() {
  return (
    <div className="grid h-screen place-items-center">
         <img src={loading} alt="" />
        </div>
  )
}
 